.strategies {
  background: var(--ui-el-background);
  border-radius: 16px;
  padding: 20px 16px;
  margin-top: 32px;

  p {
    font-weight: bold;
  }

  .switchLine {
    width: 100%;
    margin-bottom: 5rem;
    gap: 1rem;
  }

  .strategy {
    display: flex;
    align-items: baseline;
    gap: 0.5rem;
  }
  button {
    margin: 0 auto;
  }
}
